import React from "react"
import { Zoom } from "react-reveal"

function YouTubeGetID(url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

const Stories = ({ section }) => {
  const rgbaColor = section?.textOptionColor?.rgb
  const rgba = `${rgbaColor?.r},${rgbaColor?.g},${rgbaColor?.b},${rgbaColor?.a}`

  return (
    <div className="hero-stories-section">
      <div className={`hero-section ${section.videoLink ? null : "no-vlink"}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <Zoom delay={1000}>
                {section ? (
                  <h1
                    className="text-uppercase"
                    style={{
                      fontSize: "60px",
                      color: `${
                        section.textOptionColor ? `rgba(${rgba})` : "#fff"
                      }`,
                    }}
                  >
                    {section?.heading}
                  </h1>
                ) : null}
                {section ? (
                  <p
                    className="lead"
                    style={{
                      lineHeight: "30px",
                      color: `${
                        section.textOptionColor ? `rgba(${rgba})` : "#fff"
                      }`,
                    }}
                  >
                    {section?.description}
                  </p>
                ) : null}
              </Zoom>
            </div>
          </div>
        </div>
        <div
          className="background-media hero-bg-image"
          style={{
            background: `url(${section?.bannerForMobile?.asset?.url})`,
          }}
        >
          <div className={`hero-video-bg`}>
            {/* <video muted loop autoPlay>
              <source src={section?.videoLink} type="video/mp4" />
            </video> */}
            {section.videoLink ? (
              <iframe
                src={`${
                  section?.videoLink
                }?rel=0&controls=0&modestbranding=1&autohide=1&autoplay=1&playlist=${YouTubeGetID(
                  section?.videoLink
                )}&loop=1&showinfo=0&mute=1`}
                frameBorder="0"
                allow="autoplay"
                allowFullScreen
              ></iframe>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Stories
