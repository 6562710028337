import React from "react"

class NonVideos extends React.Component {
  render() {
    const { thumbnailImage, videoTitle, videoDesc, isCategroy } = this.props
    console.log("terst", thumbnailImage)
    return (
      <div
        className={`col-md-4 col-lg-3 mb-5 ${
          isCategroy ? "mb-3 col-sm-6 col-6" : null
        }`}
      >
        <div
          className={`video-item ${isCategroy ? "video-category-wrap" : null}`}
        >
          <div
            className={`video-thumbnail `}
            style={{
              backgroundImage: `url(${
                thumbnailImage ? thumbnailImage : "//via.placeholder.com/300"
              })`,
            }}
          />

          <p className="video-text">
            <span className="video-title">{videoTitle}</span>
            <br />
            <span className="video-desc">{videoDesc}</span>
          </p>
        </div>
      </div>
    )
  }
}

export default NonVideos
