import React from "react"
// import ModalVideo from "react-modal-video"
import loadable from "@loadable/component"
import "react-modal-video/scss/modal-video.scss"

const ModalVideo = loadable(() => import("react-modal-video"))

class Videos extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    const {
      videoID,
      thumbnailImage,
      videoTitle,
      videoDesc,
      isCategroy,
    } = this.props
    return (
      <div
        className={`col-md-4 col-lg-3 mb-5 ${
          isCategroy ? "mb-3 col-sm-6 col-6" : null
        }`}
      >
        <div
          className={`video-item ${isCategroy ? "video-category-wrap" : null}`}
        >
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId={videoID}
            onClose={() => this.setState({ isOpen: false })}
          />
          <div
            className={`video-thumbnail `}
            style={{
              backgroundImage: `url(${
                thumbnailImage ? thumbnailImage : "//via.placeholder.com/300"
              })`,
              cursor: "pointer",
            }}
            onClick={this.openModal}
          />

          <p className="video-text">
            <span className="video-title">{videoTitle}</span>
            <br />
            <span className="video-desc">{videoDesc}</span>
          </p>
        </div>
      </div>
    )
  }
}

export default Videos
