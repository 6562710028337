import React from "react"
import StoriesColumn from "./storiesColumn"
import StoriesFaq from "./storiesFaq"
import "swiper/css/swiper.css"
import StoriesFS from "./storiesFslider"
import SingleImageText from "./singleImageContent"

import HeroStories from "./heroStories"

const StoriesSection = ({ sections }) => {
  // console.log("section", sections)
  // const singleText = sections.filter(item=>item.)
  return (
    <div className="py-5">
      {sections.map((section, idx) => {
        return (
          <>
            {section.__typename === "SanityHeroSection" ? (
              <HeroStories section={section} />
            ) : null}
            {section.__typename === "SanityColumnSection" ? (
              <StoriesColumn section={section} />
            ) : null}
            {section.__typename === "SanitySliderSection" ? (
              <StoriesFS section={section} />
            ) : null}
            {section.__typename === "SanitySingleImageText" ? (
              <SingleImageText section={section} />
            ) : null}

            {section.__typename === "SanityStoriesFaq" ? (
              <StoriesFaq section={section} />
            ) : null}
          </>
        )
      })}
    </div>
  )
}
export default StoriesSection
