import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"

const StoriesFslider = ({ section }) => {
  const [swiper, setSwiper] = useState(null)
  const ref = useRef(null)
  const carousel = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,

    navigation: {
      nextEl: "#featured-nextt",
      prevEl: "#featured-prevv",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  }

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      swiper.slidePrev()
    }
  }
  return (
    <div className="hero-slider-section storiesSliderWrap">
      <div className="hero-slider-wrapper related-items-swiper-wrapper">
        <div className="swiper-navigation d-none">
          <div onClick={goPrev} id="related-prevv" className="swiper-prev">
            <img src="/img/swiper-prev.svg" alt="prev" />
          </div>
          <div onClick={goNext} id="related-nextt" className="swiper-next">
            <img src="/img/swiper-next.svg" alt="next" />
          </div>
        </div>
        <LazyLoadComponent>
          <Swiper {...carousel}>
            {section.sliderContent.map((slide, idx) => {
              const rgbaColor = slide?.textOptionColor?.rgb
              const rgba = `${rgbaColor?.r},${rgbaColor?.g},${rgbaColor?.b},${rgbaColor?.a}`
              return (
                <div className="slide-wrapper" key={idx}>
                  <div className="container align-self-center">
                    <div className="row align-items-end hero-slider-row">
                      <div className="col-lg-6 col-md-4 col-sm-12  d-none d-md-block text-center text-md-left"></div>
                      <div className="col-lg-6 col-md-8 col-sm-12">
                        <h2
                          className="hero-title-text font-weight-normal"
                          style={{
                            color: `${
                              slide.textOptionColor ? `rgba(${rgba})` : "#fff"
                            }`,
                          }}
                        >
                          {slide.title}
                        </h2>
                        <p
                          className="hero-description-text"
                          style={{
                            color: `${
                              slide.textOptionColor ? `rgba(${rgba})` : "#fff"
                            }`,
                            fontSize: "16px",
                          }}
                        >
                          {slide.description}
                        </p>
                        {slide.pagelink ? (
                          <Link
                            to={slide?.pagelink?.current}
                            className="btn hero-button"
                          >
                            {slide.pagelinkname}
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="slide-bg"
                    style={{
                      background: `url(${slide?.productImage?.asset?.url})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </div>
              )
            })}
          </Swiper>
        </LazyLoadComponent>
      </div>
    </div>
  )
}
export default StoriesFslider
