import React from "react"
import placeholderImage from "@images/background-fill.jpg"
import { Container, Row, Col } from "reactstrap"
const SingleImageTextContent = ({ section }) => {
  const rgbaColor = section?.textOptionColor?.rgb
  const rgba = `${rgbaColor?.r},${rgbaColor?.g},${rgbaColor?.b},${rgbaColor?.a}`
  return (
    <>
      <div
        id="nas"
        style={{
          background: `url(${(section &&
            section.backgroundImage &&
            section.backgroundImage.asset &&
            section.backgroundImage.asset.url) ||
            placeholderImage})`,
        }}
      >
        <Container style={{ height: "inherit" }}>
          <Row style={{ height: "inherit" }}>
            <Col
              sm={12}
              md={6}
              className={`align-self-center ${
                section?.imageLocation === "right" ? "order-2" : "order-1"
              }`}
            >
              {/* <img
                src={
                  (section &&
                    section.secImage &&
                    section.secImage.asset &&
                    section.secImage.asset.fluid &&
                    section.secImage.asset.fluid.src) ||
                  placeholderImage
                }
                className="img-fluid px-0 px-md-3 px-lg-5"
                alt="singleImageText"
              /> */}
            </Col>
            <Col
              sm={12}
              md={6}
              className={`align-self-center ${
                section?.imageLocation === "right" ? "order-1" : "order-2"
              }`}
            >
              <h2
                style={{
                  color: `${
                    section.textOptionColor ? `rgba(${rgba})` : "#fff"
                  }`,
                }}
              >
                {section?.heading}
              </h2>
              <p
                style={{
                  color: `${
                    section.textOptionColor ? `rgba(${rgba})` : "#fff"
                  }`,
                }}
              >
                {section?.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default SingleImageTextContent
