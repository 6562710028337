import React, { useState, Fragment, useEffect } from "react"

import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"

import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import filterCollapse from "@images/filter-collapse.svg"
// category redesign styles
import "@components/redesign/redesign-category.scss"
// category slider
// import CategorySlider from "@components/redesign/categorySlider"
import StoriesSections from "@components/stories"
import CheckBox from "../components/checkbox"
import _ from "lodash"
import BlockContent from "@components/block/block"
import { Container, Row, Col } from "reactstrap"
import slugify from "slugify"

import client from "@components/client/client"
import imageUrlBuilder from "@sanity/image-url"

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

function CustomToggle({ children, eventKey, callback }) {
  const currentEventKey = React.useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <button
      type="button"
      className={`accordion-label btn btn-link ${
        isCurrentEventKey ? "active" : ""
      }`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  )
}

const CategoryPageTemplate = ({ data, location }) => {
  const { title, description } = data.site.siteMetadata
  const ctgry = data.sanityCategory
  const [products, setProducts] = useState([])
  const [load, setLoad] = useState(true)
  const storiesSection = ctgry.storiesSection
  const [dAPlist, setDAPlist] = useState([])
  const [dBPlist, setDBPlist] = useState([])

  useEffect(() => {
    const dataLive = storiesSection.filter(
      item => item.whereToPublish === "live"
    )
    const dataAbovePlist = dataLive.filter(
      item =>
        item?.isBelowProductList === false || item?.isBelowProductList === null
    )
    const dataBelowPlist = dataLive.filter(
      item => item?.isBelowProductList === true
    )
    setDAPlist(dataAbovePlist)
    setDBPlist(dataBelowPlist)
  }, [])
  // console.log("dataAbovePlist", dAPlist)
  // console.log("dataBelowPlist", dBPlist)

  const [seriesData, setSeriesData] = useState([])
  const [voltageData, setVoltageData] = useState([])
  const [outputVoltage, setOutputVoltage] = useState([])
  const [lumens, setLumens] = useState([])
  const [wattage, setWattage] = useState([])
  const [maxRun, setMaxRun] = useState([])
  const [rating, setRating] = useState([])
  const [dimming, setDimming] = useState([])
  const [type, setType] = useState([])
  const [size, setSize] = useState([])
  const [width, setWidth] = useState([])
  const [cutIncrements, setCutIncrements] = useState([])
  const [reel, setReel] = useState([])
  const [extrusionChannel, setExtrusionChannel] = useState([])
  const [controllersReceivers, setControllersReceivers] = useState([])
  const [cct, setCct] = useState([])
  const [finish, setFinish] = useState([])
  const [lampStyle, setLampStyle] = useState([])
  const [wireColor, setWireColor] = useState([])
  const [lsPerString, setLsPerString] = useState([])
  const [lsSpacing, setLsSpacing] = useState([])
  const [powerSupplies, setPowerSupplies] = useState([])
  const myArray = {
    series: seriesData,
    allcct: cct,
    voltage: voltageData,
    outputVoltage: outputVoltage,
    lumens: lumens,
    wattage: wattage,
    maxRun: maxRun,
    ratings: rating,
    dimming: dimming,
    type: type,
    size: size,
    width: width,
    cutIncrements: cutIncrements,
    reelLength: reel,
    extrusion: extrusionChannel,
    controllers: controllersReceivers,
    finish: finish,
    lampStyle: lampStyle,
    wirecolor: wireColor,
    lsPerString: lsPerString,
    lsSpacing: lsSpacing,
    powerSupplies: powerSupplies,
  }
  const filters = Object.entries(myArray).reduce(
    (a, [k, v]) => (!v || v.length === 0 ? a : ((a[k] = v), a)),
    {}
  )

  // React.useEffect(() => {

  // }, [handlerClick])

  const handleSeries = e => {
    handlerClick(e, setSeriesData)
  }

  const handleVoltage = e => {
    handlerClick(e, setVoltageData)
  }

  const handleOutVoltage = e => {
    handlerClick(e, setOutputVoltage)
  }

  const handleLumens = e => {
    handlerClick(e, setLumens)
  }

  const handleWattage = e => {
    handlerClick(e, setWattage)
  }

  const handleMaxRun = e => {
    handlerClick(e, setMaxRun)
  }

  const handleRating = e => {
    handlerClick(e, setRating)
  }

  const handleDimmable = e => {
    handlerClick(e, setDimming)
  }

  const handleType = e => {
    handlerClick(e, setType)
  }

  const handleSize = e => {
    handlerClick(e, setSize)
  }

  const handleWidth = e => {
    handlerClick(e, setWidth)
  }

  const handleCutIncrements = e => {
    handlerClick(e, setCutIncrements)
  }

  const handleReel = e => {
    handlerClick(e, setReel)
  }

  const handleExtrusionsChannel = e => {
    handlerClick(e, setExtrusionChannel)
  }

  const handleControllersReceivers = e => {
    handlerClick(e, setControllersReceivers)
  }

  const handleCCT = e => {
    handlerClick(e, setCct)
  }

  const handleFinish = e => {
    handlerClick(e, setFinish)
  }

  const handleLampStyle = e => {
    handlerClick(e, setLampStyle)
  }

  const handleWireColor = e => {
    handlerClick(e, setWireColor)
  }

  const handleLsPerString = e => {
    handlerClick(e, setLsPerString)
  }

  const handleLsSpacing = e => {
    handlerClick(e, setLsSpacing)
  }
  const handlePowerSupplies = e => {
    handlerClick(e, setPowerSupplies)
  }

  function handlerClick(e, setMyData) {
    const myVar = e.target

    if (myVar.value && !myVar.checked) {
      setMyData(prev => {
        return prev.filter(f => f !== myVar.value)
      })
    }
    if (myVar.value && myVar.checked) {
      setMyData(prev => {
        return [...prev, myVar.value]
      })
    }
  }

  // const allSlider = ctgry.slider.filter(
  //   slide => slide.whereToPublish === "live"
  // )
  const params = `&& title == ${`"` + ctgry.title + `"`}`
  const query = `*[_type == "category" ${params}]{ _id, title, product[]->{ id, title, newProduct, discontinued, slug { current }, filters[]{ series[]->{ title }, voltage[]->{ title }, outputVoltage[]->{ title }, type[]->{ title }, lampStyle[]->{ title }, wirecolor[]->{ title }, lsPerString[]->{ title }, lsSpacing[]->{ title }, lumens[]->{ title }, size[]->{ title }, allcct[]->{ title }, wattage[]->{ title }, cutIncrements[]->{ title }, width[]->{ title }, reelLength[]->{ title }, maxRun[]->{ title }, finish[]->{ title }, dimming[]->{ title }, ratings[]->{ title }, extrusion[]->{ title }, controllers[]->{ title }, powerSupplies[]->{ title } },shortdescrption, productImage[]{ alt, image{ asset->{ url } } } } }`

  // const test = { products: { id: "id", title: "title" } }
  React.useEffect(() => {
    client
      .fetch(query)
      .then(res => {
        setProducts(res[0].product)
        setLoad(false)
      })
      .catch(err => err)
  }, [])

  let output = []
  function getResults(items, params) {
    items.map(item => {
      item &&
        item.filters &&
        item.filters.map(filter => {
          let objectsCount = 0
          let matches = 0
          for (let param in params) {
            objectsCount++
          }

          for (let param in params) {
            filter &&
              filter[param] &&
              filter[param].length &&
              filter[param].filter(filterItem => {
                if (params[param].includes(filterItem.title)) {
                  matches++
                }
              })
          }

          if (matches === objectsCount) {
            output.push(item)
          }
        })
    })

    return output
  }
  if (_.isEmpty(filters) === false) {
    getResults(products, filters)
  }

  const handleAllData = () => {
    window.location.reload()
  }

  const allproducts = _.isEmpty(filters) === false ? output : products

  return (
    <Layout location={location} title={title}>
      <SEO title={ctgry.title} description={description} />
      {/* 
			{ctgry.slide ? (
				<LazyLoadComponent>
					<PrizmCarousel slider={ctgry.slider} />
				</LazyLoadComponent>
			) : null}*/}
      {/* {allSlider.length !== 0 ? <CategorySlider slider={allSlider} /> : null} */}
      <StoriesSections sections={dAPlist} />

      <div>
        <Container
          className="px-lg-0 products-wrapped"
          // style={{
          //   paddingTop: allSlider.length !== 0 ? 0 : 155,
          //   paddingBottom: allSlider.length !== 0 ? 0 : 60,
          // }}
        >
          <Row className=" justify-content-between">
            <div className="sidebar-col col-md-4 col-lg-3">
              <div>
                <div className="category-sidebar">
                  <Accordion className="category-filters-accordion">
                    <Card>
                      <Card.Header>
                        <div className="d-flex align-items-center justify-content-between category-filter-header">
                          <div>
                            <h6 className="category-name text-uppercase d-inline-block m-0">
                              {/*display Spektrum Smart Lighting category title as Spektrum Smart*/}
                              {ctgry.title === "Spektrum"
                                ? "Spektrum Smart"
                                : ctgry.title}
                            </h6>{" "}
                            <span className="pl-1 pr-2 d-inline-block ">|</span>
                            <button
                              className="subcategory category-reset"
                              onClick={() => handleAllData()}
                            >
                              RESET
                            </button>
                          </div>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="filters"
                            className="filter-collapse-btn"
                          >
                            <img
                              src={filterCollapse}
                              alt="Filters"
                              width="25px"
                            />
                          </Accordion.Toggle>
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey="filters">
                        <Card.Body>
                          <div className="category-accordion-wrapper">
                            <Accordion>
                              <Card>
                                <Card.Header>
                                  <CustomToggle eventKey="seriesFilter">
                                    Series
                                  </CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="seriesFilter">
                                  <Card.Body>
                                    {ctgry &&
                                      ctgry.seriesFilter.map(value => (
                                        <CheckBox
                                          name="Series"
                                          id={value.id}
                                          value={value.title}
                                          handleData={handleSeries}
                                        />
                                      ))}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>

                            <Accordion>
                              <Card>
                                <Card.Header>
                                  <CustomToggle eventKey="inputVoltage">
                                    Input Voltage
                                  </CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="inputVoltage">
                                  <Card.Body>
                                    {ctgry &&
                                      ctgry.inputVoltage.map(value => (
                                        <CheckBox
                                          key={value.id}
                                          name="Input Voltage"
                                          id={value.id}
                                          value={value.title}
                                          handleData={handleVoltage}
                                        />
                                      ))}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>

                            {ctgry.title === "Downlighting" ||
                            ctgry.title === "Task Lighting" ? (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="sizeFilter"
                                    >
                                      Size
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="sizeFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.sizeFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name="Size"
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleSize}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) : null}

                            {ctgry.title !== "Power Supplies" ? (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="cctFilter"
                                    >
                                      CCT / Color Temperature
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="cctFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.cctFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name="CCT / Color Temperature"
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleCCT}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) : null}

                            {ctgry.title === "Power Supplies" ? (
                              <Fragment>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="outputVoltage">
                                        Output Voltage
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="outputVoltage">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.outputVoltage.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Output Voltage"
                                              id={value.id}
                                              value={value.title}
                                              handleData={handleOutVoltage}
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </Fragment>
                            ) : null}

                            {ctgry.title === "Seasonal" ||
                            ctgry.title === "Spektrum" ||
                            ctgry.title === "TwinklyPro" ||
                            ctgry.title === "Power Supplies" ? null : (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="lumensFilter"
                                    >
                                      Lumens
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="lumensFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.lumensFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name="Lumens"
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleLumens}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            )}

                            {ctgry.title === "Task Lighting" && (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="finishFilter"
                                    >
                                      Finish
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="finishFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.finishFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name="Finish"
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleFinish}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            )}

                            {ctgry.title === "Power Supplies" ||
                            ctgry.title === "Trulux" ? (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="wattageFilter"
                                    >
                                      {ctgry.title === "Power Supplies"
                                        ? "Wattage"
                                        : "Wattage / Foot"}
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="wattageFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.wattageFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name="Wattage / Foot"
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleWattage}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) : null}

                            {ctgry.title === "Downlighting" ||
                            ctgry.title === "Architectural" ||
                            ctgry.title === "Power Supplies" ||
                            ctgry.title === "Linear Lighting" ||
                            ctgry.title === "Seasonal" ? (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="dimmingFilter"
                                    >
                                      Dimming
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="dimmingFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.dimmingFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name="Dimming"
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleDimmable}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) : null}

                            {ctgry.title === "Downlighting" ||
                            ctgry.title === "Architectural" ||
                            ctgry.title === "Task Lighting" ||
                            ctgry.title === "Seasonal" ? (
                              <Fragment>
                                {ctgry.title === "Seasonal" ? (
                                  <Accordion>
                                    <Card>
                                      <Card.Header>
                                        <CustomToggle
                                          as={Button}
                                          variant="link"
                                          eventKey="lampStyleFilter"
                                        >
                                          Lamp Style
                                        </CustomToggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey="lampStyleFilter">
                                        <Card.Body>
                                          {ctgry &&
                                            ctgry.lampStyleFilter.map(value => (
                                              <CheckBox
                                                key={value.id}
                                                name="Lamp Style"
                                                id={value.id}
                                                value={value.title}
                                                handleData={handleLampStyle}
                                              />
                                            ))}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                ) : null}
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="typeFilter">
                                        {ctgry.title === "Seasonal"
                                          ? "Socket Type"
                                          : "Fixture Type"}
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="typeFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.typeFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name={
                                                ctgry.title === "Seasonal"
                                                  ? "Socket Type"
                                                  : "Fixture Type"
                                              }
                                              id={value.id}
                                              value={value.title}
                                              handleData={handleType}
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </Fragment>
                            ) : null}

                            {ctgry.title === "Seasonal" ? (
                              <Fragment>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="wireColorFilter">
                                        Wire Color
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="wireColorFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.wireColorFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Wire Color"
                                              id={value.id}
                                              value={value.title}
                                              handleData={handleWireColor}
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="lsPerStringFilter">
                                        Lamps / Sockets Per String
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="lsPerStringFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.lsPerStringFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Lamps / Sockets Per String"
                                              id={value.id}
                                              value={value.title}
                                              handleData={handleLsPerString}
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="lsSpacingFilter">
                                        Lamp / Socket Spacing
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="lsSpacingFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.lsSpacingFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Lamp / Socket Spacing"
                                              id={value.id}
                                              value={value.title}
                                              handleData={handleLsSpacing}
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </Fragment>
                            ) : null}

                            {ctgry.title === "Downlighting" ||
                            ctgry.title === "Architectural" ||
                            ctgry.title === "Power Supplies" ||
                            ctgry.title === "Task Lighting" ||
                            ctgry.title === "Trulux" ? (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="ratingsFilter"
                                    >
                                      {ctgry.title === "Trulux"
                                        ? "IP Rating"
                                        : "Ratings / Certifications"}
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="ratingsFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.ratingsFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name={
                                              ctgry.title === "Trulux"
                                                ? "IP Rating"
                                                : "Ratings / Certifications"
                                            }
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleRating}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) : null}

                            {ctgry.title === "Trulux" ||
                            ctgry.title === "Linear Lighting" ||
                            ctgry.title === "Seasonal" ? (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle
                                      as={Button}
                                      variant="link"
                                      eventKey="reelLengthFilter"
                                    >
                                      {ctgry.title === "Seasonal"
                                        ? "Length"
                                        : "Reel Length"}
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="reelLengthFilter">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.reelLengthFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name={
                                              ctgry.title === "Seasonal"
                                                ? "Length"
                                                : "Reel Length"
                                            }
                                            id={value.id}
                                            value={value.title}
                                            handleData={handleReel}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) : null}

                            {ctgry.title === "Trulux" ||
                            ctgry.title === "Linear Lighting" ? (
                              <Fragment>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="cutIncrementsFilter">
                                        Cut Increments
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="cutIncrementsFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.cutIncrementsFilter.map(
                                            value => (
                                              <CheckBox
                                                key={value.id}
                                                name="Cut Increments"
                                                id={value.id}
                                                value={value.title}
                                                handleData={handleCutIncrements}
                                              />
                                            )
                                          )}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="maxRunFilter">
                                        Max Run Length
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="maxRunFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.maxRunFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Max Run Length"
                                              id={value.id}
                                              value={value.title}
                                              handleData={handleMaxRun}
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </Fragment>
                            ) : null}

                            {ctgry.title === "Trulux" ? (
                              <Fragment>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="widthFilter">
                                        Width
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="widthFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.widthFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Width"
                                              id={value.id}
                                              value={value.title}
                                              handleData={handleWidth}
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="extrusionFilter">
                                        Extrusions / Channel
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="extrusionFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.extrusionFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Extrusions / Channel"
                                              id={value.id}
                                              value={value.title}
                                              handleData={
                                                handleExtrusionsChannel
                                              }
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <CustomToggle eventKey="controllersFilter">
                                        Controllers / Receivers
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="controllersFilter">
                                      <Card.Body>
                                        {ctgry &&
                                          ctgry.controllersFilter.map(value => (
                                            <CheckBox
                                              key={value.id}
                                              name="Controllers / Receivers"
                                              id={value.id}
                                              value={value.title}
                                              handleData={
                                                handleControllersReceivers
                                              }
                                            />
                                          ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </Fragment>
                            ) : null}
                            {ctgry.title === "Trulux" ? (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <CustomToggle eventKey="powerSupplies">
                                      Power Supplies
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="powerSupplies">
                                    <Card.Body>
                                      {ctgry &&
                                        ctgry.powerSuppliesFilter.map(value => (
                                          <CheckBox
                                            key={value.id}
                                            name="Power Supplies"
                                            id={value.id}
                                            value={value.title}
                                            handleData={handlePowerSupplies}
                                          />
                                        ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) : null}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="products-col col-md-8 col-lg-9">
              <div className="category-products-wrapper">
                {allproducts.length !== 0 ? (
                  <Row>
                    {allproducts.map(prod => {
                      const slug =
                        prod.slug && prod.slug.current
                          ? prod.slug.current
                          : slugify(prod.title)
                      return (
                        <LazyLoadComponent>
                          <Col
                            md={4}
                            sm={6}
                            key={prod.id}
                            className="mb-4 col-6"
                          >
                            <div className="category-product">
                              <Link to={`/${slug}`}>
                                <div className="product-box">
                                  <div className="product-image-wrapper">
                                    {prod.newProduct ? (
                                      <span className="product-status">
                                        New
                                      </span>
                                    ) : null}
                                    {prod.productImage &&
                                    prod.productImage.length !== 0 ? (
                                      <img
                                        src={urlFor(
                                          prod &&
                                            prod.productImage[0] &&
                                            prod.productImage[0].image
                                        )
                                          .width(316)
                                          .height(316)
                                          .url()}
                                        alt={prod.title}
                                      />
                                    ) : null}
                                  </div>
                                  <div className="product-text-wrapper pl-3">
                                    <h5 className="product-name text-body">
                                      {prod.title}
                                    </h5>
                                    {prod.discontinued ? (
                                      <p className="font-weight-bold mb-0">
                                        Discontinued
                                      </p>
                                    ) : null}
                                    {prod.shortdescrption && (
                                      <BlockContent
                                        blocks={prod.shortdescrption}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </Col>
                        </LazyLoadComponent>
                      )
                    })}
                  </Row>
                ) : (
                  <p className="lead text-center text-muted">
                    {load ? "Please Wait!" : "No Results"}
                  </p>
                )}
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <StoriesSections sections={dBPlist} />
    </Layout>
  )
}

export default CategoryPageTemplate

export const CategoryPageTemplateQuery = graphql`
  query CategoryPageTemplateQuery($title: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    sanityCategory(title: { eq: $title }) {
      id
      title
      seriesFilter {
        title
      }
      inputVoltage {
        id
        title
      }
      outputVoltage {
        id
        title
      }
      typeFilter {
        id
        title
      }
      lampStyleFilter {
        id
        title
      }
      wireColorFilter {
        id
        title
      }
      lsPerStringFilter {
        id
        title
      }
      lsSpacingFilter {
        id
        title
      }
      sizeFilter {
        id
        title
      }
      cctFilter {
        id
        title
      }
      wattageFilter {
        id
        title
      }
      cutIncrementsFilter {
        id
        title
      }
      widthFilter {
        id
        title
      }
      reelLengthFilter {
        id
        title
      }
      maxRunFilter {
        id
        title
      }
      finishFilter {
        id
        title
      }
      dimmingFilter {
        id
        title
      }
      ratingsFilter {
        id
        title
      }
      extrusionFilter {
        id
        title
      }
      controllersFilter {
        id
        title
      }
      lumensFilter {
        id
        title
      }
      powerSuppliesFilter {
        id
        title
      }
      storiesSection {
        ... on SanityColumnSection {
          isBelowProductList
          whereToPublish
          description
          heading
          subheading
          isForClickableColumn
          isForNonVideoColumnLayout
          columnContent {
            description
            subtitle
            title
            colImage {
              asset {
                fluid {
                  src
                }
              }
            }
            videoUrlLink
          }
        }
        ... on SanitySingleImageText {
          isBelowProductList
          whereToPublish
          heading
          subHeading
          description
          imageLocation
          secImage {
            asset {
              fluid {
                src
              }
            }
          }
          textOptionColor {
            hex
            rgb {
              a
              b
              g
              r
            }
          }
          backgroundImage {
            asset {
              url
              fluid {
                src
              }
            }
          }
        }
        ... on SanityHeroSection {
          isBelowProductList
          whereToPublish
          textOptionColor {
            hex
            rgb {
              a
              b
              g
              r
            }
          }
          bannerForMobile {
            asset {
              url
              fluid {
                src
              }
            }
          }
          heading
          description
          videoLink
        }
        ... on SanitySliderSection {
          isBelowProductList
          whereToPublish
          banner {
            asset {
              fluid {
                src
              }
              url
            }
          }
          sliderContent {
            pagelinkname
            title
            description
            pagelink {
              current
            }
            textOptionColor {
              hex
              rgb {
                a
                b
                g
                r
              }
            }
            productImage {
              asset {
                url
                fluid {
                  src
                }
              }
            }
          }
        }
        ... on SanityStoriesFaq {
          isBelowProductList
          whereToPublish
          faqHeading
          faqItems {
            _key
            faqAnswer
            faqQuestion
          }
        }
      }
    }
  }
`
