import React from "react"

const CheckboxComponent = ({ value, name, id, handleData }) => {
	return (
		<>
			<div className="d-block">
				<input
					name={name}
					type="checkbox"
					id={id}
					value={value}
					onChange={handleData}
				/>
				<label for={id}>{value}</label>
			</div>
		</>
	)
}

export default CheckboxComponent
