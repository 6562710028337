import sanityClient from "@sanity/client"

const client = sanityClient({
	projectId: "anbkks9g",
	dataset: "production",
	token:
		"sk5ybFcDE6crpliGhhwmhnst3tWfBwdJrNfceMnd13XQ2MouanIVMuItjdetOv3MpQJw710mDgL11Qah5FxCMzMfdzD4l39Lz5tZRB6OkHZGHVyPexn9MZLWVShA0eMDu005fMO949BpSo2jYEDHIk2ryR9wGfK2ErxnC1PVChDm204na2bx", // or leave blank to be anonymous user
	useCdn: true, // `false` if you want to ensure fresh data
})

export default client
