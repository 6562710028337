import React, { useState, useEffect } from "react"
import { Accordion, Card, Button } from "react-bootstrap"
import AccordionContext from "react-bootstrap/AccordionContext"
import filterCollapse from "@images/filter-collapse.svg"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
function CustomToggle({ children, eventKey, callback }) {
  const currentEventKey = React.useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <button
      type="button"
      className={`accordion-label btn btn-link ${
        isCurrentEventKey ? "active" : ""
      }`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  )
}
const StoriesFaq = ({ section }) => {
  const [faqView, setFaqView] = useState(6)
  const [faqViewArr, setFaqViewArr] = useState(
    section.faqItems.slice(0, faqView)
  )

  useEffect(() => {
    section.faqItems.length > 0
      ? setFaqViewArr(section.faqItems.slice(0, faqView))
      : setFaqViewArr(section.faqItems)
  }, [])

  const lastItem = section.faqItems[section.faqItems.length - 1]
  const lastItemSliced = faqViewArr[faqViewArr.length - 1]

  const handleChange = () => {
    if (lastItem._key !== lastItemSliced._key) {
      setFaqViewArr(section.faqItems.slice(0, faqView + 3))
      setFaqView(faqView + 3)
    }
  }
  // console.log("faqViewArr", faqViewArr)
  // console.log("lastItem", lastItem._key)
  // console.log("lastItemSliced", lastItemSliced._key)
  return (
    <div className="container faqSection py-5">
      <Accordion className="category-filters-accordion">
        <Card>
          <Card.Header>
            <div className="d-flex align-items-center justify-content-between category-filter-header">
              <div>
                <h2 className="category-name text-uppercase d-inline-block m-0 text-blue">
                  {section.faqHeading}
                </h2>
              </div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="filters"
                className="filter-collapse-btn"
              >
                <img src={filterCollapse} alt="Filters" width="25px" />
              </Accordion.Toggle>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="filters">
            <Card.Body>
              {faqViewArr &&
                faqViewArr.map(faq => (
                  <div className="category-accordion-wrapper" key={faq?._key}>
                    <Accordion>
                      <Card>
                        <Card.Header>
                          <CustomToggle eventKey="lorem">
                            <span>{faq.faqQuestion}</span>
                          </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="lorem">
                          <Card.Body>
                            <p className="px-4">{faq.faqAnswer}</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                ))}
              {lastItem._key !== lastItemSliced._key ? (
                <div className="viewMoreBtn mt-5">
                  <button
                    className="btn btn-secondary btn-sm btn-quick-link py-3"
                    onClick={handleChange}
                    style={{ fontSize: "20px" }}
                  >
                    View More
                  </button>
                </div>
              ) : null}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}
export default StoriesFaq
