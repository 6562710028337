import React, { useEffect } from "react"
// import { Link } from "gatsby"

// import sesl from "@images/sesl.jpg"

// import iconst from "@images/iconst.jpg"
// import placeHolder from "@images/template.jpg"
import { Container, Row, Col } from "reactstrap"
// import { LazyLoadComponent } from "react-lazy-load-image-component"
import ModalVideo from "../literature/ModalVideo"
import NonVideo from "../literature/nonVideoLayout"

const StoriesColumn = ({ section }) => {
  // console.log("sectiono", section)
  return (
    <div className="column-wraps">
      <Container id="s-a">
        <Row className="text-center justify-content-center">
          <Col sm={12} md={9} className="m-auto">
            {section.heading ? (
              <h2
                className="mb-4 text-blue text-center"
                style={{ fontSize: "40px" }}
              >
                {section.heading}
              </h2>
            ) : null}
            {section.subheading ? (
              <p
                className="text-center lead font-weight-bold"
                style={{ fontSize: "18px" }}
              >
                {section.subheading}
              </p>
            ) : null}
            {section.description ? (
              <p className="text-center">{section.description}</p>
            ) : null}
          </Col>
        </Row>
        <Row className="justify-content-center">
          {section.columnContent.map((col, idx) => (
            <>
              {section.isForClickableColumn ? (
                <ModalVideo
                  key={idx}
                  videoID={col.videoUrlLink}
                  thumbnailImage={col?.colImage?.asset?.fluid?.src}
                  videoTitle={col?.title}
                  videoDesc={col?.subtitle}
                  isCategroy={true}
                />
              ) : section.isForNonVideoColumnLayout ? (
                <NonVideo
                  key={idx}
                  thumbnailImage={col?.colImage?.asset?.fluid?.src}
                  videoTitle={col?.title}
                  videoDesc={col?.subtitle}
                  isCategroy={true}
                />
              ) : (
                <div
                  className={`mb-3 ${
                    section.columnContent.length >= 4
                      ? `col-lg-3 col-md-4 col-sm-6`
                      : "col-lg-4 col-md-6 col-sm-12"
                  }`}
                >
                  <div className="quick-links-section border-0">
                    <div className="button-bottom pb-0">
                      <div className="text-center">
                        <div
                          className="img-wrap-box mb-4"
                          style={{
                            backgroundImage: `url(${
                              col.colImage !== null
                                ? col.colImage.asset?.fluid?.src
                                : "//via.placeholder.com/300x400?text=300x400"
                            })`,
                            height: "395px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>

                        <h3 className="quick-link-title text-blue font-weight-bold">
                          {col?.title}
                        </h3>

                        {col.subtitle ? (
                          <p className="text-left px-2">
                            <small>{col.subtitle}</small>
                          </p>
                        ) : null}
                        {col.description ? (
                          <p className="mb-0">{col.description}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </Row>
      </Container>
    </div>
  )
}
export default StoriesColumn
